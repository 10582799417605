import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { administratorMethods } from "../api/apiMethods";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { Orderer } from "../views/OrdererView/ordererTypes";
import { queryKeys } from "./queryKeys";

export const useAdminByIdQuery = (
    adminId?: string,
    options?: {
        enabled: boolean;
    }
) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.administrators.byId(municipalityId, adminId),
        queryFn: () => {
            if (!authUserData || !adminId) {
                throw new Error("No auth user data");
            }

            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return administratorMethods(municipalityId).getAdministrator(
                    adminId
                );
            } catch (error) {
                throw new Error("Could not get orderer data");
            }
        },
        enabled: !!authUserData && !!adminId && (!options || options.enabled),
        initialData: () =>
            (
                queryClient.getQueryData(
                    queryKeys.administrators.all(municipalityId)
                ) as {
                    userlist: Orderer[];
                }
            )?.userlist?.find((admin) => admin.customerId === adminId),
    });

    useQueryErrorHandling(
        query,
        "Kunde inte hämta uppgifter för denna beställare.",
        () => {
            navigate(-1);
        }
    );

    return query;
};
