import { useMutation, useQueryClient } from "@tanstack/react-query";
import { administratorMethods } from "../api/apiMethods";
import { UpdateAdministratorDTO } from "../api/apiTypes";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";

export const useUpdateAdminMutation = (options: {
    onSuccess: () => void;
    onError: (error: any) => void;
}) => {
    const queryClient = useQueryClient();
    const municipalityId = useSelectedMunicipalityId();

    const mutation = useMutation({
        mutationFn: async (data: {
            adminId: string;
            input: UpdateAdministratorDTO;
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return administratorMethods(municipalityId).updateAdministrator(
                    data.input,
                    data.adminId
                );
            } catch (error) {
                throw error;
            }
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.administrators.byId(
                    municipalityId,
                    variables.adminId
                ),
            });
            options.onSuccess();
        },
        onError: async (error: Response) => {
            Logger.error(error);

            let parsedError;
            try {
                parsedError = await error.json();
            } catch {}

            options.onError(parsedError);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.administrators.all(municipalityId),
            });
        },
    });

    return mutation;
};
