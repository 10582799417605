import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { administratorMethods } from "../api/apiMethods";
import { genericErrorConfig } from "../common/exceptions";
import { ModalContext } from "../components/Modal";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";

export const useDeleteAdminMutation = (options: { onSuccess: () => void }) => {
    const municipalityId = useSelectedMunicipalityId();
    const { setModalOptions } = useContext(ModalContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ adminId }: { adminId?: string }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!adminId) {
                throw new Error("No admin id");
            }

            try {
                return administratorMethods(municipalityId).deleteAdministrator(
                    adminId
                );
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (_data, variables) => {
            options.onSuccess();
        },
        onError: (err) => {
            Logger.error(err);
            setModalOptions(
                genericErrorConfig(() => {
                    setModalOptions({ visible: false });
                }, "Kunde inte ta bort Administratrors.")
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.administrators.all(municipalityId),
            });
        },
    });

    return mutation;
};
