import { useMutation, useQueryClient } from "@tanstack/react-query";
import { administratorMethods } from "../api/apiMethods";
import { CreateAdministratorDTO } from "../api/apiTypes";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";

export const useCreateAdminMutation = (options: {
    onSuccess: (createAdminId: string) => void;
    onError: (error: any) => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (input: CreateAdministratorDTO) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            let adminId: string | undefined;

            try {
                adminId = (
                    await administratorMethods(
                        municipalityId
                    ).createAdministrator(input)
                ).administratorID;

                if (!adminId) {
                    throw new Error(
                        "Could not create a user. No user id was returned"
                    );
                }
            } catch (err) {
                throw err;
            }

            return adminId;
        },
        onSuccess: options.onSuccess,
        onError: async (error: Response) => {
            Logger.error(error);

            let parsedError;
            try {
                parsedError = await error.json();
            } catch {}

            options.onError(parsedError);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.administrators.all(municipalityId),
            });
        },
    });

    return mutation;
};
