import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
    emailInvalidMessage,
    isRequiredMessage,
    phoneInvalidMessage,
    phoneNrPattern,
} from "../../common/validations";

const adminInfoFormSchema = z.object({
    firstName: z.string().min(1, { message: isRequiredMessage }),
    lastName: z.string().min(1, { message: isRequiredMessage }),
    phone: z
        .string()
        .regex(phoneNrPattern, { message: phoneInvalidMessage })
        .min(1, { message: isRequiredMessage }),
    email: z.string().email({ message: emailInvalidMessage }),
});

export interface AdminInfoFormData {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
}

export const useAdminInfoForm = (defaultValues: AdminInfoFormData) => {
    const form = useForm<AdminInfoFormData>({
        resolver: zodResolver(adminInfoFormSchema),
        mode: "onTouched",
        defaultValues: defaultValues,
    });

    return form;
};
