import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller } from "react-hook-form";
import { getValidationResultFromFormError } from "../../common/validations";
import { FormField, FormFieldSet } from "../../components/FormField";
import { CellBlockFooter } from "../../components/Grid/CellBlock";
import TextInput from "../../components/TextInput";
import { AdminInfoFormData, useAdminInfoForm } from "./AdminInfoView.hooks";

interface Props {
    onCancel: () => void;
    onSubmit: (data: AdminInfoFormData) => void;
    isNewForm?: boolean;
    isSubmitLoading?: boolean;
    default: AdminInfoFormData;
}

const AdminInfoForm = (props: Props) => {
    const form = useAdminInfoForm(props.default);

    return (
        <form onSubmit={form.handleSubmit(props.onSubmit)}>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="firstName"
                        control={form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                required
                                autoComplete="on"
                                placeholder="Förnamn"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="lastName"
                        control={form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                required
                                autoComplete="on"
                                placeholder="Efternamn"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="phone"
                        control={form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                autoComplete="on"
                                required
                                placeholder="Telefon"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="email"
                        control={form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                autoComplete="on"
                                required
                                placeholder="E-post"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>

            <DevTool control={form.control} />
            <CellBlockFooter
                disableSubmit={
                    !form.formState.isValid || !form.formState.isDirty
                }
                hasChangesText={
                    !props.isNewForm && form.formState.isDirty
                        ? "Förmuläret har osparade ändringar."
                        : undefined
                }
                marginTop
                isLoading={props.isSubmitLoading}
                onCancel={props.onCancel}
                submitButtonType="submit"
            />
        </form>
    );
};

export default AdminInfoForm;
