import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/Container";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Table from "../../components/Table/Table";
import { useAllAdministratorsQuery } from "../../queries/useAllAdministratorsQuery";
import { Administrator } from "./administratorTypes";

const columnHelper = createColumnHelper<Administrator>();
const columns = [
    columnHelper.accessor(
        (row: Administrator) => row.firstName + " " + row.lastName,

        {
            id: "name",
            header: () => "Namn",
            cell: (props) => props.getValue(),
            meta: {
                getCellContext: () => {
                    return {
                        length: 3,
                    };
                },
            },
        }
    ),
    columnHelper.accessor((row: Administrator) => row.municipalPhoneNumber, {
        id: "phone",
        header: () => "Telefonnummer",
        enableSorting: false,
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    length: 3,
                };
            },
        },
    }),
    columnHelper.accessor((row: Administrator) => row.municipalEmail, {
        id: "email",
        header: () => "E-postadress",
        enableSorting: false,
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    length: 3,
                };
            },
        },
    }),
];

const AdminListingView = () => {
    const { pathname } = useLocation();
    const allAdmininistratorsQuery = useAllAdministratorsQuery();
    const navigate = useNavigate();
    const data = useMemo(
        () => allAdmininistratorsQuery.data,
        [allAdmininistratorsQuery.data]
    );

    const table = useReactTable({
        data: data || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <Container>
            <SubHeader
                title="Administratörer"
                rightContent={
                    <Button
                        size="small"
                        onClick={() => {
                            navigate(pathname + "/skapa-ny");
                        }}
                        title="Lägg till"
                    />
                }
            />
            <Plate spaceBottom={true} overScroll>
                {allAdmininistratorsQuery.isLoading ||
                allAdmininistratorsQuery.isLoading ? (
                    <Loader />
                ) : !allAdmininistratorsQuery.data?.length ? (
                    <EmptyState text="Det finns inga Administratörer." />
                ) : (
                    <Table
                        table={table}
                        getLinkTo={(data) =>
                            pathname + "/" + encodeURI(data.customerId ?? "")
                        }
                    />
                )}
            </Plate>
        </Container>
    );
};

export default AdminListingView;
