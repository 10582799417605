export const routes = {
    /**
     * absolutePath - /callback/matkonto/return
     */
    matkontoCallback: "/callback/matkonto/return",
    municipality: {
        /**
         * absolutePath - municipality
         */
        index: "municipality",

        /**
         * absolutePath - municipality/select
         */
        select: "select",

        /**
         * absolutePath - municipality/denied
         */
        denied: "denied",

        /**
         * absolutePath - municipality/error
         */
        error: "error",
        byId: {
            /**
             * absolutePath - municipality/:municipalityId
             */
            index: ":municipalityId",

            /**
             * absolutePath - municipality/:municipalityId/matkonto/verify
             */
            matkontoVerify: "matkonto/verify",
            customers: {
                /**
                 * absolutePath - municipality/:municipalityId/slutkunder
                 */
                index: "slutkunder",

                /**
                 * absolutePath - municipality/:municipalityId/slutkunder/skapa-ny
                 */
                new: "skapa-ny",

                /**
                 * absolutePath - municipality/:municipalityId/slutkunder/:customerId
                 */
                byId: ":customerId",
            },
            orderers: {
                /**
                 * absolutePath - municipality/:municipalityId/bestallare
                 */
                index: "bestallare",

                /**
                 * absolutePath - municipality/:municipalityId/bestallare/skapa-ny
                 */
                new: "skapa-ny",

                /**
                 * absolutePath - municipality/:municipalityId/bestallare/:ordererId
                 */
                byId: ":ordererId",
            },
            units: {
                /**
                 * absolutePath - municipality/:municipalityId/enheter
                 */
                index: "enheter",

                /**
                 * absolutePath - municipality/:municipalityId/enheter/skapa-ny
                 */
                new: "skapa-ny",

                /**
                 * absolutePath - municipality/:municipalityId/enheter/:unitId
                 */
                byId: ":unitId",
            },
            administrators: {
                /**
                 * absolutePath - municipality/:municipalityId/administratorer
                 */
                index: "administratorer",

                /**
                 * absolutePath - municipality/:municipalityId/administratorer/skapa-ny
                 */
                new: "skapa-ny",

                /**
                 * absolutePath - municipality/:municipalityId/administratorer/:adminId
                 */
                byId: ":adminId",
            },
        },
    },
    municipalityAccessError: () =>
        `/${routes.municipality.index}/${routes.municipality.error}` as const,
    municipalitySelect: () =>
        `/${routes.municipality.index}/${routes.municipality.select}` as const,
    municipalityAccessDenied: () =>
        `/${routes.municipality.index}/${routes.municipality.denied}` as const,
    municipalityById: (id: string) =>
        `/${routes.municipality.index}/${id}` as const,
    matkontoVerify: (municipalityId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.matkontoVerify
        }` as const,
    customers: (municipalityId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.customers.index
        }` as const,
    customerById: (municipalityId: string, customerId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.customers.index
        }/${customerId}` as const,
    orderers: (municipalityId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.orderers.index
        }` as const,
    ordererById: (municipalityId: string, ordererId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.orderers.index
        }/${ordererId}` as const,
    units: (municipalityId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.units.index
        }` as const,
    unitById: (municipalityId: string, unitId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.units.index
        }/${unitId}` as const,
    administrators: (municipalityId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.administrators.index
        }` as const,
    administratorsById: (municipalityId: string, customerId: string) =>
        `${routes.municipalityById(municipalityId)}/${
            routes.municipality.byId.administrators.index
        }/${customerId}` as const,
} as const;
