import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { genericErrorConfig } from "../../common/exceptions";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Cell, { CellHeading } from "../../components/Grid/Cell";
import CellBlock, {
    CellBlockProperty,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { useDeleteAdminMutation } from "../../mutations/useDeleteAdminMutation";
import { useUpdateAdminMutation } from "../../mutations/useUpdateAdminMutation";
import { useAdminByIdQuery } from "../../queries/useAdminByIdQuery";
import { routes } from "../../routes";
import AdminInfoForm from "./AdminInfoForm";
import { AdminInfoFormData } from "./AdminInfoView.hooks";
export interface AdminInfoData {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    customerCreationAllowed: boolean;
}

const AdminInfoView = () => {
    const { adminId } = useParams<{ adminId: string }>();
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const navigate = useNavigate();
    const adminQuery = useAdminByIdQuery(adminId, {
        enabled: true,
    });
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };
    const { setModalOptions } = useContext(ModalContext);

    const updateAdminMutation = useUpdateAdminMutation({
        onSuccess: () => {
            setIsEditing(false);
        },
        onError: (error) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde uppdatera beställare."
                )
            );
        },
    });

    const deleteAdministratorMutation = useDeleteAdminMutation({
        onSuccess: () => {
            setModalOptions({ visible: false });
            navigate(routes.administrators(municipalityId));
        },
    });
    const showDeletionConfirmModal = () => {
        setModalOptions({
            visible: true,
            title: `Ta bort administrator?`,
            subtitle: `Är du säker på att du vill ta bort ${adminQuery.data?.firstName} ${adminQuery.data?.lastName}?`,
            buttons: [
                <Button
                    size="small"
                    title="Ta bort"
                    theme="red"
                    onClick={() =>
                        deleteAdministratorMutation.mutate({ adminId })
                    }
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    const onUpdateAdministrator = (data: AdminInfoFormData) => {
        if (
            !adminId ||
            !data.email ||
            !data.firstName ||
            !data.lastName ||
            !data.phone
        ) {
            return;
        }
        updateAdminMutation.mutate({
            adminId,
            input: {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                municipalityPhone: data.phone,
            },
        });
    };

    const onCancel = () => {
        toggleEditMode();
    };

    const formLoading =
        adminQuery.isLoading || deleteAdministratorMutation.isPending;
    return (
        <Container>
            <SubHeader
                backArrow
                title={`${adminQuery.data?.firstName} ${adminQuery.data?.lastName}`}
            />
            <Plate padding="large" spaceBottom={true}>
                {adminQuery.isLoading ? (
                    <Loader />
                ) : (
                    <Grid gutter={space.padding}>
                        <Cell gutter={space.padding}>
                            <CellHeading>Personuppgifter</CellHeading>
                            <CellBlock
                                active={isEditing}
                                noOutlines={false}
                                onEdit={() => toggleEditMode()}>
                                {isEditing ? (
                                    <>
                                        <CellBlockSection>
                                            <CellBlockTitle>
                                                Ändra personuppgifter
                                            </CellBlockTitle>
                                        </CellBlockSection>
                                        <CellBlockSection>
                                            <AdminInfoForm
                                                onCancel={onCancel}
                                                onSubmit={onUpdateAdministrator}
                                                isSubmitLoading={formLoading}
                                                default={{
                                                    firstName:
                                                        adminQuery.data
                                                            ?.firstName,
                                                    lastName:
                                                        adminQuery.data
                                                            ?.lastName,

                                                    phone: adminQuery.data
                                                        ?.municipalPhoneNumber,

                                                    email: adminQuery.data
                                                        ?.municipalEmail,
                                                }}
                                            />
                                        </CellBlockSection>
                                    </>
                                ) : (
                                    <>
                                        <CellBlockSection>
                                            <CellBlockTitle>
                                                Ändra personuppgifter
                                            </CellBlockTitle>
                                        </CellBlockSection>
                                        <CellBlockSection>
                                            <CellBlockSection>
                                                <CellBlockProperty>
                                                    Förnamn
                                                </CellBlockProperty>
                                                <CellBlockValue>
                                                    {adminQuery.data?.firstName}
                                                </CellBlockValue>
                                            </CellBlockSection>
                                            <CellBlockSection>
                                                <CellBlockProperty>
                                                    Efternamn
                                                </CellBlockProperty>
                                                <CellBlockValue>
                                                    {adminQuery.data?.lastName}
                                                </CellBlockValue>
                                            </CellBlockSection>
                                            <CellBlockSection>
                                                <CellBlockProperty>
                                                    Mobiltelefon
                                                </CellBlockProperty>
                                                <CellBlockValue>
                                                    {
                                                        adminQuery.data
                                                            ?.municipalPhoneNumber
                                                    }
                                                </CellBlockValue>
                                            </CellBlockSection>
                                            <CellBlockSection>
                                                <CellBlockProperty>
                                                    E-post
                                                </CellBlockProperty>
                                                <CellBlockValue>
                                                    {
                                                        adminQuery.data
                                                            ?.municipalEmail
                                                    }
                                                </CellBlockValue>
                                            </CellBlockSection>
                                        </CellBlockSection>
                                    </>
                                )}
                            </CellBlock>
                        </Cell>
                    </Grid>
                )}
                {authUserData?.municipalityRole === "ADMIN" && (
                    <Button
                        theme="inverted"
                        title="Ta bort"
                        onClick={() => showDeletionConfirmModal()}
                        wrapperStyle={{
                            overflow: "hidden",
                            marginLeft: "auto",
                            marginTop: space.padding,
                        }}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default AdminInfoView;
