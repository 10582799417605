import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ORDERER_DUPLICATE_EMAIL_MESSAGE,
    genericErrorConfig,
} from "../../common/exceptions";
import { HybrisFieldValidationResponse } from "../../common/types";
import Container from "../../components/Container";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import { useCreateAdminMutation } from "../../mutations/useCreateAdminMutation";
import AdminInfoForm from "./AdminInfoForm";
import { AdminInfoFormData } from "./AdminInfoView.hooks";

const AdminCreateView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setModalOptions } = useContext(ModalContext);
    const createAdmin = (data: AdminInfoFormData) => {
        if (data.email && data.firstName && data.lastName && data.phone) {
            createAdminMutation.mutate({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                municipalityPhone: data.phone,
            });
        }
    };

    const createAdminMutation = useCreateAdminMutation({
        onSuccess: (createAdminId) => {
            navigate(
                `${location.pathname.substr(
                    0,
                    location.pathname.lastIndexOf("/")
                )}/${createAdminId}`,

                { replace: true }
            );
        },
        onError: (error) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde ej skapa administratör.",
                    getModalErrorText(error)
                )
            );
        },
    });

    const isSubmitLoading = createAdminMutation.isPending;

    return (
        <Container>
            <SubHeader backArrow title={"Lägg till administratör"} />
            <Plate padding="large" spaceBottom={true}>
                <AdminInfoForm
                    onCancel={() => navigate(-1)}
                    isSubmitLoading={isSubmitLoading}
                    isNewForm={true}
                    onSubmit={(data) => createAdmin(data)}
                    default={{}}
                />
            </Plate>
        </Container>
    );
};

const getModalErrorText = (response: HybrisFieldValidationResponse) => {
    if (!response?.errors?.length) {
        return "Ett okänt fel uppstod";
    }

    if (
        response.errors.find(
            (error) => error.message === ORDERER_DUPLICATE_EMAIL_MESSAGE
        )
    ) {
        return "En användare med den e-postadressen du uppgav existerar redan.";
    }

    return (
        response.errors.map((error) => error.message).join(", ") ||
        "Ett okänt fel uppstod"
    );
};

export default AdminCreateView;
