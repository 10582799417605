import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { administratorMethods } from "../api/apiMethods";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

export const useAllAdministratorsQuery = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.administrators.all(municipalityId),
        queryFn: () => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            try {
                return administratorMethods(
                    municipalityId
                ).getAllAdministrators();
            } catch (error) {
                throw new Error((error as any)?.message);
            }
        },
        select: ({ userlist }) => userlist,
        enabled: !!authUserData && authUserData.municipalityRole === "ADMIN",
        staleTime: Infinity,
    });

    useQueryErrorHandling(query, "Kunde inte hämta beställare.");

    return query;
};
