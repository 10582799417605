import * as Sentry from "@sentry/react";
import React from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { pathAuthCallback, pathSignIn } from "./auth/authConst";
import AdminAuthorized from "./components/AdminAuthorized";
import UserContextProvider from "./components/AuthenticatedUserContextProvider";
import { CriticalErrorFallback } from "./components/CriticalErrorBoundary";
import CustomerCreationAuthorized from "./components/CustomerCreationAuthorized";
import ModalContextProvider from "./components/ModalContextProvider";
import { routes } from "./routes";
import { trimLeadingSlash } from "./utils/urlUtils";
import AdminCreateView from "./views/AdminView/AdminCreateView";
import AdminInfoView from "./views/AdminView/AdminInfoView";
import AdminListingView from "./views/AdminView/AdminListingView";
import AuthCallbackView from "./views/AuthCallbackView";
import AuthSignInView from "./views/AuthSignInView";
import { Authenticated } from "./views/Authenticated";
import Authorized from "./views/Authorized";
import MatkontoCallbackRedirect from "./views/CalbackViews/MatkontoCallbackRedirect";
import MatkontoCallbackView from "./views/CalbackViews/MatkontoCallbackView";
import CustomerDetailsView from "./views/CustomerView/CustomerDetailsView";
import CustomerListingView from "./views/CustomerView/CustomerListingView";
import NewCustomerView from "./views/CustomerView/NewCustomerView";
import Layout from "./views/Layout";
import MinimalLayout from "./views/MinimalLayout";
import MunicipalityAccessDenied from "./views/MunicipalityView/MunicipalityAccessDenied";
import MunicipalityAccessError from "./views/MunicipalityView/MunicipalityAccessError";
import MunicipalityAuthorization from "./views/MunicipalityView/MunicipalityAuthorization";
import SelectMunicipalityView from "./views/MunicipalityView/SelectMunicipalityView";
import CreateOrdererView from "./views/OrdererView/CreateOrdererView";
import EditOrdererView from "./views/OrdererView/EditOrdererView";
import OrdererListingView from "./views/OrdererView/OrdererListingView";
import CreateUnitView from "./views/UnitView/CreateUnitView";
import UnitDetailsView from "./views/UnitView/UnitDetailsView";
import UnitListingView from "./views/UnitView/UnitListingView";

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    {
        //https://github.com/getsentry/sentry-javascript/discussions/6912#discussioncomment-8792266
        element: (
            <Sentry.ErrorBoundary fallback={CriticalErrorFallback}>
                <Outlet />
            </Sentry.ErrorBoundary>
        ),
        children: [
            {
                path: pathSignIn,
                element: <AuthSignInView />,
            },
            {
                path: pathAuthCallback,
                element: <AuthCallbackView />,
            },
            {
                element: (
                    <Authenticated>
                        <ModalContextProvider>
                            <UserContextProvider>
                                <Authorized>
                                    <Outlet />
                                </Authorized>
                            </UserContextProvider>
                        </ModalContextProvider>
                    </Authenticated>
                ),
                children: [
                    {
                        path: trimLeadingSlash(routes.matkontoCallback),
                        element: <MatkontoCallbackRedirect />,
                    },
                    {
                        path: routes.municipality.index,
                        element: (
                            <MinimalLayout>
                                <Outlet />
                            </MinimalLayout>
                        ),
                        children: [
                            {
                                path: routes.municipality.select,
                                element: <SelectMunicipalityView />,
                            },
                            {
                                path: routes.municipality.denied,
                                element: <MunicipalityAccessDenied />,
                            },
                            {
                                path: routes.municipality.error,
                                element: <MunicipalityAccessError />,
                            },
                        ],
                    },
                    // municipality routes that require authorization check
                    {
                        path: "*",
                        element: (
                            <MunicipalityAuthorization>
                                <Outlet />
                            </MunicipalityAuthorization>
                        ),
                        children: [
                            {
                                path: `${routes.municipality.index}/${routes.municipality.byId.index}`,
                                element: <Layout />,
                                children: [
                                    {
                                        path: routes.municipality.byId
                                            .matkontoVerify,
                                        element: <MatkontoCallbackView />,
                                    },
                                    {
                                        path: routes.municipality.byId.customers
                                            .index,
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <CustomerListingView />
                                                ),
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .customers.new,
                                                element: (
                                                    <CustomerCreationAuthorized>
                                                        <NewCustomerView />
                                                    </CustomerCreationAuthorized>
                                                ),
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .customers.byId,
                                                element: (
                                                    <CustomerDetailsView />
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: routes.municipality.byId.orderers
                                            .index,
                                        element: (
                                            <AdminAuthorized>
                                                <Outlet />
                                            </AdminAuthorized>
                                        ),
                                        children: [
                                            {
                                                index: true,
                                                element: <OrdererListingView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .orderers.new,
                                                element: <CreateOrdererView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .orderers.byId,
                                                element: <EditOrdererView />,
                                            },
                                        ],
                                    },
                                    {
                                        path: routes.municipality.byId.units
                                            .index,
                                        element: (
                                            <AdminAuthorized>
                                                <Outlet />
                                            </AdminAuthorized>
                                        ),
                                        children: [
                                            {
                                                index: true,
                                                element: <UnitListingView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .units.new,
                                                element: <CreateUnitView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .units.byId,
                                                element: <UnitDetailsView />,
                                            },
                                        ],
                                    },
                                    {
                                        path: routes.municipality.byId
                                            .administrators.index,
                                        element: (
                                            <AdminAuthorized>
                                                <Outlet />
                                            </AdminAuthorized>
                                        ),
                                        children: [
                                            {
                                                index: true,
                                                element: <AdminListingView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .units.new,
                                                element: <AdminCreateView />,
                                            },
                                            {
                                                path: routes.municipality.byId
                                                    .administrators.byId,
                                                element: <AdminInfoView />,
                                            },
                                        ],
                                    },
                                    {
                                        index: true,
                                        element: (
                                            <Navigate
                                                to={
                                                    routes.municipality.byId
                                                        .customers.index
                                                }
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: "*",
                                element: <Navigate to={"/"} />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
